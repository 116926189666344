// Footer.tsx
import { FC } from "react";
import { Link } from "react-router-dom";

const Footer: FC = () => {
	return (
		<footer className=" bg-gray-200 p-4 text-center absolute bottom-0 h-auto w-full">
			&copy; {new Date().getFullYear()} Felo Tadros
			<div className="mx-auto w-full max-w-screen-xl">
				<Link to="/about" className="text-blue-500 hover:underline">
					About
				</Link>
				<Link
					to="/contact"
					className="text-blue-500 hover:underline ml-4"
				>
					Contact
				</Link>
			</div>
		</footer>
	);
};

export default Footer;

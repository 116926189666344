import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./index.css";
import AppRoutes from "./routes";
ReactDOM.render(
	<Router>
		<Header />
		<AppRoutes />
		<Footer />
	</Router>,
	document.getElementById("root")
);

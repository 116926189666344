import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Contact: React.FC = () => {
	return (
		<div className="bg-gray-100  py-6 text-center">
			<h1 className="text-4xl font-bold mb-4">Contact Me</h1>
			<div className="mb-4">
				<a
					href="https://github.com/FTadros"
					target="_blank"
					rel="noopener noreferrer"
					className="text-blue-500 hover:underline mr-4"
				>
					<FontAwesomeIcon icon={faGithub} />
				</a>
				<a
					href="https://linkedin.com/in/felo-tadros"
					target="_blank"
					rel="noopener noreferrer"
					className="text-blue-500 hover:underline"
				>
					<FontAwesomeIcon icon={faLinkedin} />
				</a>
			</div>
		</div>
	);
};

export default Contact;

import { FC } from "react";
import { Link } from "react-router-dom";

const Header: FC = () => {
	return (
		<header className="bg-gray-200 p-4 text-center">
			<Link to="/" className="text-blue-500 mr-4">
				<h1 className="text-2xl font-bold text-black">Gradebook</h1>
			</Link>
		</header>
	);
};

export default Header;

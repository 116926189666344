const About = () => {
	return (
		<div className="bg-gray-100  py-6 text-center">
			<div className="container mx-auto px-4">
				<h1 className="text-4xl font-bold mb-6 text-center">
					About Me
				</h1>
				<p className="text-lg mb-6 text-center">
					Welcome to my gradebook! My name is Felo Tadros, A computer
					engineering student at Lasonde School of Engineering at
					York.
				</p>
				<h2 className="text-2xl font-bold mt-8 mb-4">What Is this?</h2>
				<p className="text-lg mb-6">
					Started off as an Excel sheet that I used to keep track of
					my grades, this gradebook has evolved into a full-fledged
					web application that I use to manage my academic progress.
				</p>
			</div>
		</div>
	);
};

export default About;

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

interface Item {
	name: string;
	weight: number;
	score: number;
}

interface GradebookProps {
	minRows: number;
	maxRows: number;
}

function MinGrade({ minRows, maxRows }: GradebookProps) {
	const initialItems: Item[] = loadItemsFromStorage() || [
		{ name: "Homework", weight: 20, score: 65 },
		{ name: "Assignment", weight: 20, score: 83 },
		{ name: "Exam", weight: 60, score: 87 },
	];

	const [items, setItems] = useState<Item[]>(initialItems);

	const handleNameChange = (index: number, name: string) => {
		const newItems = [...items];
		newItems[index].name = name;
		setItems(newItems);
	};

	const handleScoreChange = (index: number, score: number) => {
		const newItems = [...items];
		newItems[index].score = score;
		setItems(newItems);
	};

	const handleWeightChange = (index: number, weight: number) => {
		const newItems = [...items];
		newItems[index].weight = weight;
		setItems(newItems);
	};

	const addRow = () => {
		if (items.length < maxRows) {
			setItems([...items, { name: "", weight: 0, score: 0 }]);
		}
	};

	const removeRow = (index: number) => {
		if (items.length > minRows) {
			const newItems = items.filter((_, i) => i !== index);
			setItems(newItems);
		}
	};

	const clearLocalStorage = () => {
		localStorage.removeItem("gradebookItems");
		window.location.reload();
	};

	useEffect(() => {
		saveItemsToStorage(items);
	}, [items]);

	const calculateCurrentGrade = () => {
		const totalWeight = items.reduce((sum, item) => sum + item.weight, 0);
		const weightedScores = items.map(
			(item) => (item.score / 100) * item.weight
		);
		const currentGrade =
			(weightedScores.reduce((sum, score) => sum + score, 0) /
				totalWeight) *
			100;

		if (isNaN(currentGrade) || totalWeight !== 100) {
			return "Unable to calculate. Please ensure weights and scores are valid.";
		}

		return currentGrade.toFixed(2) + "%";
	};

	return (
		<div className="h-auto max-w-xl mx-auto mt-4 bg-white p-8 rounded-lg shadow-lg">
			<h1 className="text-2xl font-bold mb-4">Min Grade</h1>
			<table className="w-full border-collapse border border-gray-300">
				<thead>
					<tr>
						<th className="p-2 border border-gray-300">Item</th>
						<th className="p-2 border border-gray-300">
							Weight (%)
						</th>
						<th className="p-2 border border-gray-300">
							Score (%)
						</th>
						<th className="p-2 border border-gray-300"></th>
					</tr>
				</thead>
				<tbody>
					{items.map((item, index) => (
						<tr key={index}>
							<td className="p-2 border border-gray-300">
								<input
									type="text"
									value={item.name}
									onChange={(e) =>
										handleNameChange(index, e.target.value)
									}
									className="w-full p-2 bg-gray-100"
								/>
							</td>
							<td className="p-2 border border-gray-300">
								<input
									type="number"
									min="0"
									max="100"
									value={item.weight}
									onChange={(e) =>
										handleWeightChange(
											index,
											parseFloat(e.target.value)
										)
									}
									className="w-full p-2 bg-gray-100"
								/>
							</td>
							<td className="p-2 border border-gray-300">
								<input
									type="number"
									min="0"
									max="100"
									value={item.score}
									onChange={(e) =>
										handleScoreChange(
											index,
											parseFloat(e.target.value)
										)
									}
									className="w-full p-2 bg-gray-100"
								/>
							</td>
							<td className="p-2 border border-gray-300">
								<button
									onClick={() => removeRow(index)}
									disabled={items.length <= minRows}
									className={`text-red-600 ${
										items.length <= minRows
											? "text-gray-300 cursor-not-allowed"
											: "cursor-pointer"
									}`}
								>
									<FontAwesomeIcon icon={faTimes} />
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{items.length < maxRows && (
				<button
					onClick={addRow}
					className="mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
				>
					Add Row
				</button>
			)}
			<button
				onClick={clearLocalStorage}
				className="mt-4 ml-2 bg-red-500 text-white p-2 rounded hover:bg-red-600"
			>
				Reset
			</button>
			<p className="mt-4 text-lg font-semibold">
				Min Grade: {calculateCurrentGrade()}
			</p>
		</div>
	);
}

export default MinGrade;

// Functions to save and load items from localStorage
function saveItemsToStorage(items: Item[]) {
	localStorage.setItem("gradebookItems", JSON.stringify(items));
}

function loadItemsFromStorage(): Item[] | null {
	const storedItems = localStorage.getItem("gradebookItems");
	return storedItems ? JSON.parse(storedItems) : null;
}

import { FC } from "react";
import Button from "./Button";

const Home: FC = () => {
	return (
		<div className="max-w-xl mx-auto mt-4 bg-gray-100 flex justify-center h-auto">
			<div className="bg-white p-8 rounded-lg shadow-lg">
				<h1 className="text-3xl font-bold mb-4">Gradebook</h1>
				<p className="text-gray-700 mb-4">
					Welcome to the gradebook. Here you can keep track of your
					grades, as well as various grade-related calculations.
				</p>
				<Button to="/final-grade">Min Grade</Button>
				<div className="mt-2"></div>
				{/* <Button to="/min-grade">Minimum Grade</Button>	 */}
			</div>
		</div>
	);
};

export default Home;

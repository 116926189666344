import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

interface ButtonProps {
	to: string;
	children: ReactNode;
}

const Button: FC<ButtonProps> = ({ to, children }) => {
	return (
		<Link
			to={to}
			className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 inline-block"
		>
			{children}
		</Link>
	);
};

export default Button;

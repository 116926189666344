import { SpeedInsights } from "@vercel/speed-insights/react";
import { Route, Routes } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import MinGrade from "./components/MinGrade";

const AppRoutes: React.FC = () => (
	<>
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/about" element={<About />} />
			<Route path="/contact" element={<Contact />} />
			<Route
				path="/final-grade"
				element={<MinGrade minRows={2} maxRows={10} />}
			/>

			<Route path="*" element={<Home />} />
		</Routes>
		<SpeedInsights />
	</>
);

export default AppRoutes;
